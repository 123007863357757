import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardFooter, Row } from "reactstrap";

import FooterAdd from "../../view/TableBooking/FooterAdd";
import { useSelector } from "react-redux";
const Footer = (props) => {
  const launchData = useSelector((state) => state.launch);
  const [bodySettings, setBodySettings] = useState({
    backgroundColor: "white",
    border: "none",
  });
  const [footerSettings, setFooterSettings] = useState({
    backgroundColor: "#EAEAEA",
    justifyContent: "center",
  });
  const [footerFonts, setFooterFonts] = useState({
    fontWeight: "normal",
    fontSize: "11px",
    color: "#8D8D8D",
  });

  //  "footer": {
  //   "backgroundColor": "",
  //   "fontSettings": {
  //       "name": "",
  //       "color": "",
  //       "size": "",
  //       "bold": "",
  //       "italic": "",
  //       "alignment": "left"
  //   },
  //   "height": ""
  //  }

  useEffect(() => {
    if (
      launchData.themeSettings.data &&
      launchData.themeSettings.data.status === 1
    ) {
      const body = launchData.themeSettings.data.themeSettings.body;
      const footer = launchData.themeSettings.data.themeSettings.footer;

      setBodySettings((prev) => {
        return {
          ...prev,
          backgroundColor: body.backgroundColor
            ? `${body.backgroundColor}`
            : prev.backgroundColor,
        };
      });
      setFooterSettings((prev) => {
        return {
          ...prev,
          backgroundColor: footer.backgroundColor
            ? `${footer.backgroundColor}`
            : prev.backgroundColor,
          justifyContent: footer.fontSettings.alignment
            ? `${footer.fontSettings.alignment}`
            : prev.justifyContent,
        };
      });
      setFooterFonts((prev) => {
        return {
          ...prev,
          color: footer.fontSettings.color
            ? `${footer.fontSettings.color}`
            : prev.color,
          size: footer.fontSettings.size
            ? `${footer.fontSettings.size}`
            : prev.color,
          fontFamily: `${footer.fontSettings.fontFamily}`,
          fontWeight: footer.fontSettings.bold ? "bold" : "normal",
          fontStyle: footer.fontSettings.italic ? "italic" : "normal",
        };
      });
    }
  }, [launchData.themeSettings]);

  return (
    <CardFooter style={bodySettings}>
      <FooterAdd
        outletData={props.outletData}
        modalTitle={props.modalTitle}
        isOpenBL={props.isOpenBL}
      />
      <Row className="d-flex border-top fixed-bottom" style={footerSettings}>
        <div style={{ width: "160px" }}>
          <small>
            <em style={footerFonts}>Powered by </em>
            <Link
              to=""
              onClick={() => window.open("https://lucidpos.com/", "_blank")}
              style={footerFonts}
            >
              <strong>LUCID POS</strong>
            </Link>
          </small>
        </div>
      </Row>
    </CardFooter>
  );
};

export default Footer;
