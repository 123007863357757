import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

//custome hooks
// import useLaunch from "../../core/utils/useLaunch"

import {
  getOutletDetails,
  getOutletList,
  handlePageId,
  handleViewPage,
} from "../../redux/launch";
// import useWalkin from "../../Hooks/useWalkin";
import View from "../../View";
import Loading from "../Loading";
import { getMobileCountryCode, getTitleList } from "../../redux/options";
import { getPropertyList } from "../../redux/propertyData";
// import WalkinStatus from "../walkIn/WalkinStatus";

const CheckIn = (props) => {
  const dispatch = useDispatch();
  const launch = useSelector((state) => state.launch);
  const propertyData = useSelector((state) => state.propertyList);
  const optionsData = useSelector((state) => state.options);
  const [token, setToken] = useState(launch.token);
  //const [walkinData, setWalkingData] = useState(sessionStorage.getItem('walkinData') ? JSON.parse(sessionStorage.getItem('walkinData')) : '')
  const [title, setTitle] = useState("Mr.");

  const [optionsTitle, setOptionsTitle] = useState([]);
  const [mobileNo, setMobileNo] = useState("");
  const [name, setName] = useState("");
  const [pax, setPax] = useState(0);

  const [imageUrl, setImageurl] = useState();
  const [outletCode, setOutletCode] = useState("");
  const [outletName, setOutletName] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [saving, setSaving] = useState(false);
  const [checkInId, setCheckInId] = useState("");
  const [buttonUnSelectedStyle, setButtonUnSelectedStyle] = useState({
    backgroundColor: "white",
    color: "black",
  });
  //  const useWalkinData = useWalkin(checkInId, tokenData.token)
  //  const [refreshW, setRefreshW] = useState(false)
  const [logoStyle, setLogoStyle] = useState({
    display: "flex",
    justifyContent: "left",
  });

  const [propertyNameFontSettings, setPropertyNameFontSettings] = useState({
    color: "black",
  });

  const [outletNameFontSettings, setOutletNameFontSettings] = useState({
    color: "black",
  });

  const [bodySettings, setBodySettings] = useState({
    backgroundColor: "white",
    borderTop: "none",
  });
  const [bodyStyle, setBodyStyle] = useState({
    backgroundColor: "white",
    border: "none",
    paddingTop: "80px",
  });

  const [footerSettings, setFooterSettings] = useState({
    backgroundColor: "#EAEAEA",
    justifyContent: "center",
  });
  const [footerFonts, setFooterFonts] = useState({
    fontWeight: "normal",
    fontSize: "11px",
    color: "#8D8D8D",
  });
  const [headerStyle, setHeaderStyle] = useState({
    border: "none",
    backgroundColor: "#EAEAEA",
    zIndex: "5",
  });

  const titleRef = useRef(true);

  useEffect(() => {
    if (launch.themeSettings && launch.themeSettings.data.status === 1) {
      console.log(launch.themeSettings);
      const body = launch.themeSettings.data.themeSettings.body;
      const footer = launch.themeSettings.data.themeSettings.footer;
      const header = launch.themeSettings.data.themeSettings.header;
      setButtonUnSelectedStyle((prev) => {
        return {
          ...prev,
          backgroundColor: body.nonSelectedButtonSettings.backgroundColor
            ? `${body.nonSelectedButtonSettings.backgroundColor}`
            : prev.backgroundColor,
          color: body.nonSelectedButtonSettings.fontSettings.color
            ? `${body.nonSelectedButtonSettings.fontSettings.color}`
            : prev.color,
          fontFamily: body.nonSelectedButtonSettings.fontSettings.fontFamily
            ? `${body.nonSelectedButtonSettings.fontSettings.fontFamily}`
            : "",
        };
      });

      setPropertyNameFontSettings((prev) => {
        return {
          ...prev,
          color: `${header.propertyNameFontSettings.color}`,
          fontFamily: `${header.propertyNameFontSettings.fontFamily}`,
        };
      });

      setOutletNameFontSettings((prev) => {
        return {
          ...prev,
          color: `${header.outletNameFontSettings.color}`,
          fontFamily: `${header.outletNameFontSettings.fontFamily}`,
        };
      });

      setHeaderStyle((prev) => {
        return {
          ...prev,
          backgroundColor: header.backgroundColor
            ? `${header.backgroundColor}`
            : prev.backgroundColor,
          height: `${header.height}`,
          backgroundImage: `url(${header.backgroundImages[0]})`,
        };
      });

      setPropertyNameFontSettings((prev) => {
        return {
          ...prev,
          color: `${header.propertyNameFontSettings.color}`,
          fontFamily: `${header.propertyNameFontSettings.fontFamily}`,
        };
      });

      setOutletNameFontSettings((prev) => {
        return {
          ...prev,
          color: `${header.outletNameFontSettings.color}`,
          fontFamily: `${header.outletNameFontSettings.fontFamily}`,
        };
      });

      setBodySettings((prev) => {
        return {
          ...prev,
          backgroundColor: body.backgroundColor
            ? `${body.backgroundColor}`
            : prev.backgroundColor,
        };
      });

      setLogoStyle((prev) => {
        return {
          ...prev,

          justifyContent: header.logoPosition
            ? `${header.logoPosition}`
            : prev.changeIconColor,
          flexDirection: header.logoPosition === "right" ? "row-reverse" : "",
        };
      });

      setBodyStyle((prev) => {
        return {
          ...prev,
          backgroundColor: body.backgroundColor
            ? `${body.backgroundColor}`
            : prev.backgroundColor,
          backgroundImage: `url(${body.backgroundImages[0]})`,
          border: body.border ? `${body.border}` : prev.border,
          fontFamily: `${body.fontSettings.fontFamily}`,
          color: `${body.fontSettings.color}`,
          fontWeight: body.fontSettings.bold ? "bold" : "normal",
          fontStyle: body.fontSettings.italic ? "italic" : "normal",
        };
      });

      setFooterSettings((prev) => {
        return {
          ...prev,
          backgroundColor: footer.backgroundColor
            ? `${footer.backgroundColor}`
            : prev.backgroundColor,
          justifyContent: footer.fontSettings.alignment
            ? `${footer.fontSettings.alignment}`
            : prev.justifyContent,
        };
      });

      setFooterFonts((prev) => {
        return {
          ...prev,
          color: footer.fontSettings.color
            ? `${footer.fontSettings.color}`
            : prev.color,
          size: footer.fontSettings.size
            ? `${footer.fontSettings.size}`
            : prev.color,
          fontFamily: `${footer.fontSettings.fontFamily}`,
          fontWeight: footer.fontSettings.bold ? "bold" : "normal",
          fontStyle: footer.fontSettings.italic ? "italic" : "normal",
        };
      });
    }
  }, [launch.themeSettings]);

  useEffect(() => {
    if (launch.outletDetails.outletDetails) {
      const otDetails = launch.outletDetails.outletDetails;
      setImageurl(otDetails.imageUrl);
    }

    if (launch.token && titleRef.current) {
      setToken(launch.token);
      dispatch(getPropertyList(launch.token));
      dispatch(getOutletList({ propertyId, token: launch.token }));
      dispatch(getTitleList(launch.token));
      dispatch(getMobileCountryCode(launch.token));

      titleRef.current = false;
    }
  }, [launch.outletDetails.outletDetails]);

  useEffect(() => {
    if (propertyData.propertyData.propertyList) {
      propertyData.propertyData.propertyList.forEach((item) => {
        if (item.propertyId === propertyId) {
          setPropertyName(item.propertyName);
        }
      });
      //  setPropertyName(propty.propertyName)
    }
  }, [propertyData.propertyData, propertyId]);

  useEffect(() => {
    if (launch.outletListData.outletList) {
      launch.outletListData.outletList.forEach((item) => {
        if (item.outletCode === outletCode) {
          setOutletName(item.outletName);
        }
      });
    }
  }, [launch.outletListData.outletList, outletCode]);

  const subMitHandler = (e) => {
    e.preventDefault();
    // const url = 'https://dev.lucidits.com/lucidapi/V1/LUCIDPOS/SaveOutletCheckIn'
    axios
      .post(
        `${process.env.REACT_APP_BASE_API_URL}lucidapi/V1/LUCIDPOS/SaveOutletCheckIn`,
        {
          OutletCode: outletCode,
          NoOfPax: pax,
          NoOfTables: 1,
          AssignedTables: [],
          GuestTitle: title,
          GuestName: name,
          GuestMobileNo: mobileNo,
          GuestEmailId: "",
          Status: 2,
          IsSelfCheckIn: true,

          SystemDetails: {
            ApplicationName: "",
            ApplicationVersion: "1.0",
            BrowserName: "",
            BrowserVersion: "",
            DeviceId: "",
            DeviceType: "Tab",
            IP: "",
            Mac: "",
            OPS: "",
            Source: "TabletPOS",
            SystemName: "Yuvaraj",
            SystemTimeZoneId: 1,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
          "Content-Type": "application/json",
        }
      )
      .then((res) => {
        setSaving(false);
        if (res.data.errorCode === 0) {
          setCheckInId(res.data.response.checkInId);
          // dispatch(handleViewPage(<View checkInId = {res.data.response.checkInId}  PropertyId={ launch.paramData.propertyId}/>))
          //  dispatch(handlePageId(2))
          //  setView(2)
        }
        if (res.data.errorCode === 1) {
          const error = res.data;
          alert(error.message);
        }
      });
  };

  useEffect(() => {
    const paramData = launch.paramData;
    setPropertyId(paramData.propertyId);
    setOutletCode(paramData.outletCode);
    if (launch.token && paramData.outletCode) {
      dispatch(
        getOutletDetails({
          tokenOption: launch.token,
          outletCode: paramData.outletCode,
        })
      );
    }
  }, [launch.paramData, launch.token]);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    if (imageUrl) favicon.href = imageUrl;
  }, [imageUrl]);

  useEffect(() => {
    if (optionsData.optionsTitle.length > 0)
      setOptionsTitle(optionsData.optionsTitle);
  }, [optionsData.optionsTitle]);

  //     useEffect(() => {
  //       setWalkingData(sessionStorage.getItem('walkinData') ? JSON.parse(sessionStorage.getItem('walkinData')) : useWalkinData)
  //       setRefreshW(false)
  //       console.log(JSON.parse(sessionStorage.getItem('walkinData')))
  //       console.log(walkinData)
  //     }, [sessionStorage.getItem('walkinData')])
  // for props down purpose

  //  const walkingBookingStatusHandler = () => {
  //    useWalkinData.walkingStatusDetails()
  //    }

  if (checkInId) {
    // return <WalkinStatus walkingStatusDetails={walkinData} walkingBookingStatusHandler={walkingBookingStatusHandler} refreshW = {refreshW}/>
    return (
      <View checkInId={checkInId} PropertyId={launch.paramData.propertyId} />
    );
  } else
    return (
      <Card>
        <CardHeader className="text-dark" style={headerStyle}>
          <div style={logoStyle} className="pt-0">
            {imageUrl && (
              <img
                src={imageUrl}
                className=" rounded float-start img-fluid"
                alt="..."
                style={{
                  maxHeight: "70px",
                  maxWidth: "200px",
                  visibility:
                    launch.themeSettings &&
                    launch.themeSettings.data.themeSettings.header.hideLogo ===
                      "true"
                      ? "hidden"
                      : "visible",
                }}
              />
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "15px",
              }}
            >
              <span className="ps-2" style={outletNameFontSettings}>
                {outletName}
              </span>{" "}
              <span className="ps-2" style={propertyNameFontSettings}>
                {propertyName}{" "}
              </span>
            </div>
            {
              // Right Side Logout
            }
          </div>
        </CardHeader>
        <CardBody
          className="d-flex justify-content-center pt-5"
          style={bodyStyle}
        >
          <div style={{ width: "50%", height: "450px" }}>
            <Form>
              <FormGroup row>
                <Label for="mobile" sm={2}>
                  Mobile No *
                </Label>
                <Col sm={2}>
                  <Input
                    id="code"
                    name="code"
                    placeholder="+91"
                    type="text"
                    disabled
                  />
                </Col>
                <Col sm={7}>
                  <Input
                    id="mobileNo"
                    name="mobileNo"
                    placeholder="Mobile No"
                    type="tel"
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="name" sm={2}>
                  Name *
                </Label>
                <Col sm={2}>
                  <Input
                    id="title"
                    name="title"
                    defaultValue={"Mr."}
                    type="select"
                    onChange={(e) => setTitle(e.target.value)}
                  >
                    {optionsTitle &&
                      optionsTitle.map((itm, id) => {
                        return <option key={id}>{itm.label}</option>;
                      })}
                  </Input>
                </Col>
                <Col sm={7}>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="Pax" sm={2}>
                  Pax *
                </Label>
                <Col sm={2}>
                  <Input
                    id="pax"
                    name="pax"
                    type="number"
                    onChange={(e) => setPax(e.target.value)}
                    min={1}
                  />
                </Col>
              </FormGroup>
            </Form>
            <div className="d-flex justify-content-center">
              <Button
                disabled={name.trim().length === 1 && !mobileNo}
                style={buttonUnSelectedStyle}
                onClick={(e) => {
                  if (mobileNo.length >= 10) {
                    if (name.length > 2) {
                      if (pax > 0) {
                        subMitHandler(e);
                        setSaving(true);
                      } else {
                        alert("Please Enter No. of Pax");
                      }
                    } else {
                      alert("Please Enter Your Name");
                    }
                  } else {
                    alert("Please Enter Valid Mobile No");
                  }
                }}
              >
                Submit
              </Button>
            </div>
            {saving && <Loading />}
          </div>
        </CardBody>
        <CardFooter style={bodySettings}>
          <Row
            className="d-flex border-top fixed-bottom"
            style={footerSettings}
          >
            <div style={{ width: "160px" }}>
              <small>
                <em style={footerFonts}>Powered by </em>
                <Link
                  to=""
                  onClick={() => window.open("https://lucidpos.com/", "_blank")}
                  style={footerFonts}
                >
                  <strong>LUCID POS</strong>
                </Link>
              </small>
            </div>
          </Row>
        </CardFooter>
      </Card>
    );
};

export default CheckIn;
