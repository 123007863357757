import React, { useState, useEffect, Fragment, useRef } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarXmark,
  faClipboard,
} from "@fortawesome/free-regular-svg-icons";
import { GiPartyPopper } from "react-icons/gi";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container, CardFooter, Button } from "reactstrap";
import { RefreshCcw } from "react-feather";
import { Link } from "react-router-dom";
import {
  Card,
  CardImg,
  CardBody,
  CardText,
  CardHeader,
  Badge,
} from "reactstrap";
import Loading from "./Loading";
import ErrorModal from "./view/TableBooking/Modals/ErrorModal";
import BookingView from "./view/TableBooking/BookingView";
import TableBookingComp from "./view/TableBooking/TableBookingComp";
import { getOutletDetails, handlePageId, handleViewPage } from "./redux/launch";
import { handleIsOpenBL } from "./redux/tableBooking";

const View = (props) => {
  const dispatch = useDispatch();
  const launch = useSelector((state) => state.launch);
  const [paramData, setParamData] = useState(launch.paramData);
  const [token, setToken] = useState(launch.token);
  const [walkingStatusDetails, setWalkingStatusDetails] = useState("");
  const [bookingId, setBookingId] = useState(props.BookingId);
  const [checkInId, setCheckInId] = useState(
    props.checkInId || launch.paramData.remarks.split("|")[1]
  );
  //launch.paramData.remarks.split('|')[1])
  const [bookingType] = useState(props.BookingType);
  const [view, setView] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");
  const walkinRef = useRef(true);
  const outletDetailsRef = useRef(true);
  const [logoStyle, setLogoStyle] = useState({
    display: "flex",
    justifyContent: "left",
  });
  //{backgroundColor: 'white', height:'100vh'}
  const [bodyStyle, setBodyStyle] = useState({
    backgroundColor: "white",
    border: "none",
    paddingTop: "80px",
  });
  const [footerSettings, setFooterSettings] = useState({
    backgroundColor: "#EAEAEA",
    justifyContent: "center",
  });
  const [footerFonts, setFooterFonts] = useState({
    fontWeight: "normal",
    fontSize: "11px",
    color: "#8D8D8D",
  });
  const [headerStyle, setHeaderStyle] = useState({
    border: "none",
    backgroundColor: "#EAEAEA",
    zIndex: "5",
  });

  const [propertyNameFontSettings, setPropertyNameFontSettings] = useState({
    color: "black",
  });

  const [outletNameFontSettings, setOutletNameFontSettings] = useState({
    color: "black",
  });
  const [buttonUnSelectedStyle, setButtonUnSelectedStyle] = useState({
    backgroundColor: "white",
    color: "black",
  });
  useEffect(() => {
    if (launch.themeSettings && launch.themeSettings.data.status === 1) {
      console.log(launch.themeSettings);
      const body = launch.themeSettings.data.themeSettings.body;
      const footer = launch.themeSettings.data.themeSettings.footer;
      const header = launch.themeSettings.data.themeSettings.header;

      setHeaderStyle((prev) => {
        return {
          ...prev,
          backgroundColor: header.backgroundColor
            ? `${header.backgroundColor}`
            : prev.backgroundColor,
          height: `${header.height}`,
          backgroundImage: `url(${header.backgroundImages[0]})`,
          backgroundRepeat: "no-repeat",
        };
      });

      setPropertyNameFontSettings((prev) => {
        return {
          ...prev,
          color: `${header.propertyNameFontSettings.color}`,
          fontFamily: `${header.propertyNameFontSettings.fontFamily}`,
        };
      });

      setOutletNameFontSettings((prev) => {
        return {
          ...prev,
          color: `${header.outletNameFontSettings.color}`,
          fontFamily: `${header.outletNameFontSettings.fontFamily}`,
        };
      });

      setBodyStyle((prev) => {
        return {
          ...prev,
          backgroundColor: body.backgroundColor
            ? `${body.backgroundColor}`
            : prev.backgroundColor,
          backgroundImage: `url(${body.backgroundImages[0]})`,
          border: body.border ? `${body.border}` : prev.border,
          fontFamily: `${body.fontSettings.fontFamily}`,
          color: `${body.fontSettings.color}`,
          fontWeight: body.fontSettings.bold ? "bold" : "normal",
          fontStyle: body.fontSettings.italic ? "italic" : "normal",
        };
      });
      setButtonUnSelectedStyle((prev) => {
        return {
          ...prev,
          backgroundColor: body.nonSelectedButtonSettings.backgroundColor
            ? `${body.nonSelectedButtonSettings.backgroundColor}`
            : prev.backgroundColor,
          color: body.nonSelectedButtonSettings.fontSettings.color
            ? `${body.nonSelectedButtonSettings.fontSettings.color}`
            : prev.color,
          fontFamily: body.nonSelectedButtonSettings.fontSettings.fontFamily
            ? `${body.nonSelectedButtonSettings.fontSettings.fontFamily}`
            : "",
        };
      });

      setFooterSettings((prev) => {
        return {
          ...prev,
          backgroundColor: footer.backgroundColor
            ? `${footer.backgroundColor}`
            : prev.backgroundColor,
          justifyContent: footer.fontSettings.alignment
            ? `${footer.fontSettings.alignment}`
            : prev.justifyContent,
        };
      });
      setFooterFonts((prev) => {
        return {
          ...prev,
          color: footer.fontSettings.color
            ? `${footer.fontSettings.color}`
            : prev.color,
          size: footer.fontSettings.size
            ? `${footer.fontSettings.size}`
            : prev.color,
          fontFamily: `${footer.fontSettings.fontFamily}`,
          fontWeight: footer.fontSettings.bold ? "bold" : "normal",
          fontStyle: footer.fontSettings.italic ? "italic" : "normal",
        };
      });
    }
  }, [launch.themeSettings]);

  useEffect(() => {
    if (launch.paramData) {
      setToken(launch.token);
      setParamData(launch.paramData);
      if (
        launch.token &&
        launch.paramData.outletCode &&
        outletDetailsRef.current &&
        launch.paramData.remarks
      ) {
        outletDetailsRef.current = false;
        dispatch(
          getOutletDetails({
            tokenOption: launch.token,
            outletCode: launch.paramData.outletCode,
          })
        );
      }
    }
  }, [launch.token, launch.paramData.outletCode]);

  useEffect(() => {
    if (props.BookingId) setBookingId(props.BookingId);
  }, [props.BookingId]);

  useEffect(() => {
    if (props.checkInId) setCheckInId(props.checkInId);
  }, [props.checkInId]);

  const viewTableBooking = () => {
    //   setView('table') // removed it due to pageid is not getting reset
    // setTimeout(() => dispatch(handleViewPage(''), 1000))
    //     dispatch(handleViewPage(''))
    // props.tableBookingHandler()
    dispatch(handlePageId(1)); // or the table view is not working on second click after coming back
    dispatch(handleIsOpenBL(true));
  };

  const walkingBookingStatusHandler = () => {
    //curl --location --request GET 'https://dev.lucidits.com/LUCIDPOSGuestTableReservationAPI/V1/GetWalkInStatusDetails?
    //PropertyId=10000000131000000002&OutletCode=HAMR&CheckInId=d6140b0bf91244f9b58e01e76bbda440'
    if (walkinRef.current)
      axios
        .get(
          `${process.env.REACT_APP_LUCIDPOS_GUEST_TABLE}GetWalkInStatusDetails`,
          {
            params: {
              PropertyId: paramData.propertyId,
              OutletCode: paramData.outletCode,
              CheckInId: checkInId || props.checkInId,
            },
            headers: { Authorization: `Bearer ${token}` }, // get from session storage from TableBooking outletList
            "Content-Type": "application/json",
          }
        )
        .then((res) => {
          if (res.data.errorCode === 0)
            setWalkingStatusDetails(res.data.response);
          if (res.data.errorCode === 1) setErrorMessage(res.data.message);
        });
    walkinRef.current = false;
  };

  useEffect(() => {
    const walking =
      paramData.remarks && paramData.remarks.split("|")[0] === "2";
    if (token) {
      if (launch.paramData.remarks.split("|")[0] === "1") {
        setView(1);
      } else if (launch.paramData.remarks == "tbr-self-chkin") {
        setView(3);
        walkingBookingStatusHandler();
      } else if (walking || bookingType) {
        walkingBookingStatusHandler();
      } else if (props.BookingId) {
        setView(1);
      }
    }
  }, [launch.paramData, token, bookingId]);

  const Wlcontent = () => {
    return (
      <Fragment>
        <div
          className="d-flex justify-content-center mt-5"
          style={{ marginBottom: "0px", color: "black" }}
        >
          <h2 style={{ fontWeight: "900" }}>
            {walkingStatusDetails &&
              walkingStatusDetails.walkInDetails.currentWaitlistNo}
          </h2>
        </div>
        <div className="d-flex justify-content-center m-0 ">
          <span style={{ marginBottom: "0px", color: "black" }}>
            <small> Your Current Wait List Number </small>
            <span
              onClick={() => {
                walkinRef.current = true;
                walkingBookingStatusHandler();
              }}
              style={{
                textDecoration: "none",
                color: "black",
                paddingLeft: "7px",
              }}
            >
              {walkingStatusDetails &&
                walkingStatusDetails.walkInDetails.showRefresh && (
                  <RefreshCcw size={15} strokeWidth="3px" className="rotate" />
                )}
            </span>
          </span>
        </div>
        <div className="d-flex justify-content-center m-0 mt-5"></div>
      </Fragment>
    );
  };

  const Confirmcontent = () => {
    return (
      <Fragment>
        <div className="d-flex justify-content-center m-0 mt-5">
          <h2 style={{ fontWeight: "900" }}>
            {" "}
            {walkingStatusDetails &&
              walkingStatusDetails.walkInDetails.tableNos}
          </h2>
        </div>
        <div className="d-flex justify-content-center m-0">
          <small>Table Number</small>
        </div>

        <div className="d-flex justify-content-center m-0 mt-5">
          {walkingStatusDetails &&
            walkingStatusDetails.walkInDetails.showOrderNow && (
              <Button
                size="sm"
                style={buttonUnSelectedStyle}
                onClick={() =>
                  window.open(
                    walkingStatusDetails.walkInDetails.orderNowLink,
                    "_self"
                  )
                }
              >
                {walkingStatusDetails.walkInDetails.orderNowDisplayName}
              </Button>
            )}
        </div>
      </Fragment>
    );
  };

  const CancelContent = () => {
    return (
      <Fragment>
        <div className="d-flex justify-content-center m-0 mt-5">
          <h4>
            <Badge outline color="black">
              Cancelled
            </Badge>{" "}
          </h4>
        </div>
        <div className="d-flex justify-content-center m-0 mt-0 mb-5 pb-2">
          <small style={{ fontSize: "10px" }}>
            [
            {walkingStatusDetails &&
              walkingStatusDetails.walkInDetails.cancelReason}
            ]
          </small>
        </div>
        <div className="d-flex justify-content-center m-0 mt-5">
          {
            // for spacing prupose , not rquired for cancelled to order
            //  walkingStatusDetails && walkingStatusDetails.walkInDetails.showOrderNow &&
            //<p style={{marginBottom:'0px'}}>
            // <small><Link to='' onClick = {() => window.open(walkingStatusDetails.walkInDetails.orderNowLink, '_self')}>{walkingStatusDetails.walkInDetails.orderNowDisplayName}</Link>
            // </small></p>
          }
        </div>
      </Fragment>
    );
  };

  const WalkingStatusComponent = () => {
    return (
      <Card style={bodyStyle} className="bx">
        <CardHeader className="text-white fixed-top mb-5" style={headerStyle}>
          {walkingStatusDetails.walkInDetails.outletImageUrl &&
          launch.themeSettings &&
          launch.themeSettings.data.themeSettings.header.hideLogo ===
            "false" ? (
            <div className="d-flex justify-content-center m-0">
              <img
                src={walkingStatusDetails.walkInDetails.outletImageUrl}
                style={{
                  maxWidth: "200px",
                  maxHeight: "70px",
                  borderRadius: "10px",
                  visibility:
                    launch.themeSettings &&
                    launch.themeSettings.data.themeSettings.header.hideLogo ===
                      "true"
                      ? "hidden"
                      : "visible",
                }}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center m-0 ">
              <div
                className="m-0 pt-1 px-2"
                style={{
                  textAlign: "center",
                  background: "white",
                  borderRadius: "5px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                }}
              >
                <h5 className="m-0" style={{ color: "grey" }}>
                  {walkingStatusDetails &&
                    walkingStatusDetails.walkInDetails.outletName}{" "}
                </h5>
                <h6 style={{ color: "grey" }} className="mb-2 ">
                  {walkingStatusDetails &&
                    walkingStatusDetails.walkInDetails.propertyName}
                </h6>
              </div>
            </div>
          )}
        </CardHeader>
        <CardBody className="mt-5 pt-5 mx-0 px-0">
          <div className="d-flex justify-content-center m-0 mt-5 mb-1">
            <h4 style={{ marginBottom: "0px" }}>
              Thank you{" "}
              {walkingStatusDetails &&
                walkingStatusDetails.walkInDetails.guestName}{" "}
            </h4>
          </div>

          <div className="d-flex justify-content-center m-0 mb-4">
            {(walkingStatusDetails.walkInDetails.currentStatus === 2 ||
              walkingStatusDetails.walkInDetails.currentStatus === 3) &&
              "Your table has been confirmed"}
            {walkingStatusDetails.walkInDetails.currentStatus === 1 &&
              "Thanks for waiting, you will get your table soon"}
            {walkingStatusDetails.walkInDetails.currentStatus === 4 &&
              "Your table has been cancelled"}
          </div>
          <div className="d-flex justify-content-center m-0 mb-2">
            {(walkingStatusDetails.walkInDetails.currentStatus === 2 ||
              walkingStatusDetails.walkInDetails.currentStatus === 3) && (
              <div className="animate__animated animate__shakeY">
                <GiPartyPopper style={{ fontSize: "70px", color: "black" }} />
              </div>
            )}
            {walkingStatusDetails.walkInDetails.currentStatus === 4 && (
              <FontAwesomeIcon
                icon={faCalendarXmark}
                className="fa-solid fa-triangle-exclamation fa-fade"
                style={{ color: "black", fontSize: "70px" }}
              />
            )}
            {walkingStatusDetails.walkInDetails.currentStatus === 1 && (
              <FontAwesomeIcon
                icon={faClipboard}
                className="fa-solid fa-triangle-exclamation fa-fade"
                style={{ color: "black", fontSize: "70px" }}
              />
            )}
          </div>
          <CardText className="mt-1">
            {walkingStatusDetails.walkInDetails.currentStatus === 1 && (
              <Wlcontent />
            )}
            {(walkingStatusDetails.walkInDetails.currentStatus === 2 ||
              walkingStatusDetails.walkInDetails.currentStatus === 3) && (
              <Confirmcontent />
            )}
            {walkingStatusDetails.walkInDetails.currentStatus === 4 && (
              <CancelContent />
            )}
            <div className="d-flex justify-content-center mt-5">
              <small>Your walkin details</small>
            </div>
            <div
              className="d-flex justify-content-center "
              style={{ borderTop: "1px solid black" }}
            >
              <div
                style={{
                  width: "110px",
                  borderRight: "1px solid black",
                  marginTop: "5px",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "12px",
                }}
                className="px-3"
              >
                <small>
                  {walkingStatusDetails &&
                    walkingStatusDetails.walkInDetails.checkInDate}
                  <div>Date</div>
                </small>
              </div>
              <div
                style={{
                  width: "110px",
                  borderRight: "1px solid black",
                  marginTop: "5px",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "12px",
                }}
                className="px-3"
              >
                <small>
                  {walkingStatusDetails &&
                    walkingStatusDetails.walkInDetails.checkInTime}
                  <div>Time</div>
                </small>
              </div>
              <div
                style={{
                  width: "110px",
                  marginTop: "5px",
                  padding: "10px",
                  textAlign: "center",
                  fontSize: "12px",
                }}
                className="px-3"
              >
                {walkingStatusDetails && (
                  <small>
                    {walkingStatusDetails.walkInDetails.noOfGuest} People
                    <div style={{ paddingRight: "5px" }}>Guests</div>
                  </small>
                )}
              </div>
            </div>
          </CardText>
          <Row
            className="d-flex border-top fixed-bottom"
            style={footerSettings}
          >
            <div style={{ width: "160px" }}>
              <small>
                <em style={{ fontSize: "11px", color: "#8D8D8D" }}>
                  Powered by{" "}
                </em>
                <Link
                  to=""
                  onClick={() => window.open("https://lucidpos.com/", "_blank")}
                  style={{
                    fontWeight: "normal",
                    fontSize: "11px",
                    color: "#8D8D8D",
                  }}
                >
                  <strong>LUCID POS</strong>
                </Link>
              </small>
            </div>
          </Row>
        </CardBody>
      </Card>
    );
  };
  if (view === "table") {
    return <TableBookingComp setViewPage={props.setViewPage} isOpenBL={true} />;
  } else
    return (
      <Container fluid>
        <Row>
          <Col className="p-0">
            {!view && !walkingStatusDetails && <Loading />}
            {view === 1 && (
              <BookingView
                PropertyId={props.PropertyId}
                BookingId={props.BookingId}
                OutletCode={props.OutletCode}
                viewTableBooking={viewTableBooking}
              />
            )}
            {walkingStatusDetails && <WalkingStatusComponent />}
          </Col>
        </Row>
        <ErrorModal errorMessage={errorMessage} />
      </Container>
    );
};

export default View;
